<template>
  <div>
    <v-row class="app-preview">
      <v-col
        cols="12"
        md="9"
      >
        <template>
          <v-card
            v-if="receipt"
            class="printable--theme mb-3"
            :class="{ 'mobile-text' : $vuetify.breakpoint.xs }"
          >
            <div class="pb-2 px-5">
              <!-- Letterhead -->
              <Letterhead v-if="receipt.issuer.role !== 'Associate'" />
              <LetterheadAssociate v-else />
            </div>

            <v-divider class="printable-divider"></v-divider>

            <div class="px-5 px-sm-12 pt-2 pb-0">
              <v-row>
                <!-- Customer Details -->
                <v-col
                  cols="7"
                  sm="8"
                >
                  <p class="me-2 printable-text font-weight-medium">
                    Date Issued: {{ receipt.issued_at }}
                  </p>
                  <p class="text-decoration-underline font-weight-semibold payment-details-header mb-1">
                    Receipt From:
                  </p>
                  <p v-if="receipt.customer_type === 'Quoted' || receipt.customer_type === 'Staff rate'" class="mb-0">
                    {{ receipt.customer_name }} ({{ receipt.customer_contact_number }})
                  </p>
                  <p v-else class="mb-0">
                    {{ receipt.customer_name }}
                  </p>
                  <p class="mb-0">
                    {{ receipt.insurance.name }}
                  </p>
                  <p class="mb-0">
                    Insurance policy no.: {{ receipt.insurance_policy_number }}
                  </p>
                  <template v-if="receipt.insurance_product.name === 'Motor insurance'">
                    <p class="mb-0">
                      Car registration: {{ receipt.car_registration_number }}
                    </p>
                  </template>
                  <p class="mb-0">
                    Sum insured: {{ receipt.sum_insured }}
                  </p>
                  <template v-if="receipt.insurance_product.name === 'Motor insurance' && receipt.bluecard_finance">
                    <p class="mb-0">
                      Finance: {{ receipt.bluecard_finance.name }}
                      <span v-if="receipt.bluecard_finance.name === 'Others'">({{ receipt.bluecard_finance_remarks }})</span>
                    </p>
                  </template>
                  <template v-if="receipt.general_remarks !== null">
                    <p class="mb-0">
                      Remarks: {{ receipt.general_remarks }}
                    </p>
                  </template>
                  <p class="font-weight-semibold mb-2">
                    Branch Issued: {{ receipt.branch.title }}
                  </p>
                  <p class="payment-details-header mb-0">
                    Payment By: {{ receipt.payment_method.name }}
                  </p>
                  <template v-if="receipt.payment_method.name === 'Visa Card'">
                    <p class="mb-0">
                      Trace no.: {{ receipt.trace_number }}
                    </p>
                    <p class="mb-0">
                      Card no.: {{ receipt.partial_card_number }}
                    </p>
                  </template>
                  <p
                    v-if="receipt.invoice_number"
                    class="mb-0"
                  >
                    For invoice <span class="purple--text">{{ receipt.invoice_number }}</span>
                  </p>
                </v-col>

                <v-col>
                  <p
                    class="me-2 printable-text font-weight-medium"
                    :class="{ 'ml-n13': $vuetify.breakpoint.smAndUp, 'receipt-no-margin': $vuetify.breakpoint.xs }"
                  >
                    Official Receipt No:
                    <span class="purple--text">{{ receipt.receipt_number }}</span>
                  </p>
                  <div>
                    <qrcode-vue
                      :value="receipt.record_preview"
                      level="L"
                    />
                    <p :class="{ 'ml-n5': $vuetify.breakpoint.smAndUp, 'scan-margin': $vuetify.breakpoint.xs }">Scan for authenticity</p>
                  </div>
                </v-col>
              </v-row>
            </div>

            <Details :record="receipt" />

            <template v-if="receipt.issuer.role !== 'Associate'">
              <v-divider class="printable-divider"></v-divider>
              <Branches />
            </template>
          </v-card>
        </template>
        <v-card v-if="!receipt">
          <v-skeleton-loader
            height="100%"
            type="image, image, image, image, image, image"
          />
        </v-card>
      </v-col>

      <!-- Right Column: Receipt Action -->
      <v-col
        cols="12"
        md="3"
        class="receipt-action"
      >
        <v-card>
          <v-card-text>
            <template v-if="receipt.issuer.role !== 'Associate'">
              <v-btn
                :disabled="receipt && receipt.deleted_at != null"
                color="#25D366"
                block
                class="mb-3 white--text"
                @click="isResendDialogVisible = true; resendChannel = 'Whatsapp'"
              >
                <v-icon
                  class="me-2"
                  left
                >
                  {{ icons.mdiWhatsapp }}
                </v-icon>
                <span>{{ t('Whatsapp Resend') }}</span>
              </v-btn>
              <p>{{ t('Only use this when customer did not receive any automated WhatsApp message') }}</p>
              <v-btn
                :disabled="receipt && receipt.deleted_at != null"
                color="secondary"
                block
                class="mb-3 white--text"
                @click="isResendDialogVisible = true; resendChannel = 'SMS'"
              >
                <v-icon
                  class="me-2"
                  left
                >
                  {{ icons.mdiMessageProcessingOutline }}
                </v-icon>
                <span>{{ t('SMS Resend') }}</span>
              </v-btn>
              <p>{{ t("Only use this when customer did not receive any automated SMS message or don't have WhatsApp") }}</p>
            </template>
            <div
              v-if="receipt"
              class="mb-3"
            >
              Preview link:
              <v-row>
                <v-col cols="10">
                  <small>
                    {{ receipt.record_preview }}
                  </small>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    icon
                    @mousedown="onMouseDown"
                    @mouseup="copyPreviewLink(receipt.record_preview)"
                  >
                    <v-icon small>
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <v-btn
              :disabled="receipt && receipt.deleted_at != null"
              color="primary"
              block
              @click="printReceipt"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiPrinter }}
              </v-icon>
              <span>{{ t('Print') }}</span>
            </v-btn>
            <v-btn
              v-if="receipt && receipt.insurance_policy_url"
              :disabled="receipt && receipt.deleted_at != null"
              color="primary"
              block
              outlined
              class="mt-3"
              @click="isPolicyDialogVisible = true"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiFileDocumentOutline }}
              </v-icon>
              <span>{{ t('View Policy') }}</span>
            </v-btn>
            <v-btn
              v-if="receipt && receipt.payment_screenshot_url"
              :disabled="receipt && receipt.deleted_at != null"
              color="primary"
              block
              outlined
              class="mt-3"
              @click="isScreenshotDialogVisible = true"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiPaperclip }}
              </v-icon>
              <span>{{ t('Payment Screenshot') }}</span>
            </v-btn>
            <v-btn
              v-if="receipt && receipt.payment_method.name === 'Visa Card' && receipt.visa_receipt_url"
              :disabled="receipt && receipt.deleted_at != null"
              color="primary"
              block
              outlined
              class="mt-3"
              @click="isVisaReceiptDialogVisible = true"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiPaperclip }}
              </v-icon>
              <span>{{ t('Visa Receipt') }}</span>
            </v-btn>
          </v-card-text>

          <v-card-text
            v-if="receipt && receipt.deleted_at"
            class="text-center"
          >
            <p class="error--text mb-0">
              {{ t('This receipt has been voided.') }}
            </p>
          </v-card-text>
        </v-card>

        <v-card
          v-if="receipt && receipt.insurance_expired_at && (receipt.insurance_category.id === 1 && receipt.insurance_product.id === 1)"
          class="mt-3"
        >
          <v-card-text>
            <div class="d-flex justify-space-between">
              <span>
                Insurance expiry date:
              </span>
              <span class="font-weight-semibold">
                {{ receipt.insurance_expired_at }}
              </span>
            </div>
          </v-card-text>
        </v-card>

        <v-card
          v-if="receipt && receipt.voucher_issued_code && can('write', 'Voucher')"
          class="mt-3"
        >
          <v-card-text>
            <div class="font-weight-semibold">Voucher actions</div>
            <v-btn
              color="error"
              block
              outlined
              class="mt-3"
              @click="isDeleteVoucherDialogVisible = true"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiCardMinus }}
              </v-icon>
              <span>{{ t('Delete voucher') }}</span>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Insurance Policy dialog -->
      <v-dialog
        v-model="isPolicyDialogVisible"
        max-width="1000"
      >
        <v-card v-if="receipt">
          <v-card-title class="d-flex align-center py-3">
            <v-row>
              <v-col
                cols="12"
                sm="8"
              >
                {{ receipt.insurance_policy_name }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="12"
                sm="4"
                class="text-right"
              >
                <v-btn
                  v-if="receipt.insurance_policy_filetype !== 'pdf'"
                  icon
                  small
                  class="ml-5"
                  @click="downloadPolicy"
                >
                  <v-icon size="22">
                    {{ icons.mdiDownload }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  :href="receipt.insurance_policy_url"
                  target="_blank"
                >
                  <v-icon size="22">
                    {{ icons.mdiOpenInNew }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  @click="isPolicyDialogVisible = false"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="receipt.insurance_policy_filetype === 'pdf'">
            <iframe
              class="insurance-policy-viewer"
              :src="receipt.insurance_policy_url"
            ></iframe>
          </div>
          <div v-else>
            <v-img
              width="100%"
              :src="receipt.insurance_policy_url"
            ></v-img>
          </div>
        </v-card>
      </v-dialog>

      <!-- Payment Screenshot dialog -->
      <v-dialog
        v-model="isScreenshotDialogVisible"
        max-width="1000"
      >
        <v-card v-if="receipt">
          <v-card-title class="d-flex align-center py-3">
            <v-row>
              <v-col
                cols="12"
                sm="8"
              >
                {{ receipt.receipt_number }}_{{ receipt.issued_at.split('/').join('-') }}_{{ receipt.payment_screenshot_name }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="12"
                sm="4"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  class="ml-5"
                  @click="downloadScreenshot"
                >
                  <v-icon size="22">
                    {{ icons.mdiDownload }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  :href="receipt.payment_screenshot_url"
                  target="_blank"
                >
                  <v-icon size="22">
                    {{ icons.mdiOpenInNew }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  @click="isScreenshotDialogVisible = false"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="receipt.payment_screenshot_filetype === 'pdf'">
            <iframe
              class="payment-screenshot-viewer"
              :src="receipt.payment_screenshot_url"
            ></iframe>
          </div>
          <div v-else>
            <v-img
              width="100%"
              :src="receipt.payment_screenshot_url"
            ></v-img>
          </div>
        </v-card>
      </v-dialog>

      <!-- Visa Receipt dialog -->
      <v-dialog
        v-model="isVisaReceiptDialogVisible"
        max-width="1000"
      >
        <v-card v-if="receipt">
          <v-card-title class="d-flex align-center py-3">
            <v-row>
              <v-col
                cols="12"
                sm="8"
              >
                {{ receipt.receipt_number }}_{{ receipt.issued_at.split('/').join('-') }}_{{ receipt.visa_receipt_name }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="12"
                sm="4"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  class="ml-5"
                  @click="downloadVisaReceipt"
                >
                  <v-icon size="22">
                    {{ icons.mdiDownload }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  :href="receipt.visa_receipt_url"
                  target="_blank"
                >
                  <v-icon size="22">
                    {{ icons.mdiOpenInNew }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  @click="isVisaReceiptDialogVisible = false"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="receipt.visa_receipt_filetype === 'pdf'">
            <iframe
              class="payment-screenshot-viewer"
              :src="receipt.visa_receipt_url"
            ></iframe>
          </div>
          <div v-else>
            <v-img
              width="100%"
              :src="receipt.visa_receipt_url"
            ></v-img>
          </div>
        </v-card>
      </v-dialog>

      <!-- Resend Message dialog -->
      <v-dialog
        v-model="isResendDialogVisible"
        max-width="500"
      >
        <v-card v-if="receipt">
          <v-card-title class="d-flex align-center py-3">
            Send Receipt {{ receipt.receipt_number }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="ml-5"
              @click="isResendDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            This receipt & insurance policy details will be sent to contact number <b>673{{ receipt.customer_contact_number }}</b> via {{ resendChannel }}.
          </v-card-text>
          <v-card-actions>
            <v-btn
              :color="resendChannel === 'Whatsapp' ? '#25D366' : 'secondary'"
              block
              :loading="resendLoading"
              :disabled="resendLoading"
              class="white--text"
              @click="resendMessage()"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ resendChannel === 'Whatsapp' ? icons.mdiWhatsapp : icons.mdiMessageProcessingOutline }}
              </v-icon>
              Yes, send receipt
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Voucher dialog -->
      <v-dialog
        v-model="isDeleteVoucherDialogVisible"
        max-width="500"
      >
        <v-card v-if="receipt">
          <v-card-title class="d-flex align-center py-3">
            Delete voucher - #{{ receipt.voucher_issued_code }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="ml-5"
              @click="isDeleteVoucherDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            The voucher issued by this receipt will be remove & receipt using this voucher will be detached.
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="deleteVoucherLoading"
              :disabled="deleteVoucherLoading"
              class="white--text"
              @click="deleteVoucher"
            >
              Yes, delete voucher
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row
      v-if="receipt && receipt.voucher_issued_code"
      class="voucher-preview mt-0"
    >
      <v-col
        cols="12"
        md="9"
      >
        <VoucherPreview :voucher-code="receipt.voucher_issued_code" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref,
  onUnmounted,
  onMounted,
  inject,
} from '@vue/composition-api'
import router from '@/router'
import {
  mdiPrinter,
  mdiFileDocumentOutline,
  mdiClose,
  mdiOpenInNew,
  mdiWhatsapp,
  mdiMessageProcessingOutline,
  mdiContentCopy,
  mdiPaperclip,
  mdiDownload,
  mdiCardMinus,
} from '@mdi/js'
import QrcodeVue from 'qrcode.vue'
import {
  Letterhead,
  LetterheadAssociate,
  Details,
  Branches,
} from '@/components/apps/previews'
import VoucherPreview from '@/views/apps/voucher/VoucherPreview'
import receiptStoreModule from './receiptStoreModule'

export default {
  components: {
    Letterhead,
    LetterheadAssociate,
    Details,
    Branches,
    QrcodeVue,
    VoucherPreview,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const RECEIPT_APP_STORE_MODULE_NAME = 'app-receipt'

    if (!store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) {
      store.registerModule(RECEIPT_APP_STORE_MODULE_NAME, receiptStoreModule)
    }

    // Properties
    const receipt = ref(null)
    const isPolicyDialogVisible = ref(false)
    const isScreenshotDialogVisible = ref(false)
    const isVisaReceiptDialogVisible = ref(false)
    const isResendDialogVisible = ref(false)
    const resendLoading = ref(false)
    const resendChannel = ref(null)
    const mouseIsDown = ref(false)
    const copyToClipboard = ref(false)
    const isDeleteVoucherDialogVisible = ref(false)
    const deleteVoucherLoading = ref(false)

    // Methods
    const fetchReceipt = () => {
      store
        .dispatch('app-receipt/fetchReceipt', { id: router.currentRoute.params.id })
        .then(response => {
          receipt.value = response.data.data
          if (receipt.value.insurance_expired_at) {
            const requestDate = receipt.value.insurance_expired_at.split('/')
            receipt.value.insurance_expired_at = `${requestDate[1]}/${requestDate[2]}`
          }
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching receipt. Please refresh!')
        })
    }
    const resendMessage = () => {
      resendLoading.value = true
      store
        .dispatch(`app-receipt/${resendChannel.value === 'Whatsapp' ? 'sendWhatsapp' : 'sendSMS'}`, { id: router.currentRoute.params.id })
        .then(response => {
          snackbarService.success(response.data.message)
          resendLoading.value = false
          isResendDialogVisible.value = false
        })
        .catch(error => {
          resendLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending message. Please refresh!')
        })
    }
    const onMouseDown = () => {
      mouseIsDown.value = true
      copyToClipboard.value = true
      setTimeout(() => {
        if (mouseIsDown.value) {
          copyToClipboard.value = false
        }
      }, 2000)
    }
    const copyPreviewLink = link => {
      mouseIsDown.value = false
      if (copyToClipboard.value) {
        navigator.clipboard.writeText(link)
        snackbarService.success('Link copied to clipboard')
      }
    }
    const downloadPolicy = () => {
      fetch(receipt.value.insurance_policy_url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = receipt.value.insurance_policy_name
          link.click()
        })
        .catch(() => {
          snackbarService.error('Something went wrong while downloading policy. Please refresh!')
        })
    }
    const downloadScreenshot = () => {
      fetch(receipt.value.payment_screenshot_url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${receipt.value.receipt_number}_${receipt.value.issued_at.split('/').join('-')}_${receipt.value.payment_screenshot_name}`
          link.click()
        })
        .catch(() => {
          snackbarService.error('Something went wrong while downloading screenshot. Please refresh!')
        })
    }
    const downloadVisaReceipt = () => {
      fetch(receipt.value.visa_receipt_url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${receipt.value.receipt_number}_${receipt.value.issued_at.split('/').join('-')}_${receipt.value.visa_receipt_name}`
          link.click()
        })
        .catch(() => {
          snackbarService.error('Something went wrong while downloading visa receipt. Please refresh!')
        })
    }
    const deleteVoucher = () => {
      isDeleteVoucherDialogVisible.value = true
      deleteVoucherLoading.value = true
      store
        .dispatch('voucherStore/voidVoucher', { code: receipt.value.voucher_issued_code })
        .then(response => {
          snackbarService.success(response.data.message)
          deleteVoucherLoading.value = false
          isDeleteVoucherDialogVisible.value = false
          receipt.value.voucher_issued_code = null
        })
        .catch(error => {
          deleteVoucherLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending message. Please refresh!')
        })
    }
    const printReceipt = () => window.print()

    window.onbeforeprint = () => {
      document.title = `receipt-${receipt.value.receipt_number}`
    }

    window.onafterprint = () => {
      document.title = 'Niagamas - A Clericle product'
    }

    // Mounted
    onMounted(() => fetchReceipt())

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      receipt,
      printReceipt,
      resendMessage,
      resendLoading,
      isPolicyDialogVisible,
      isScreenshotDialogVisible,
      isVisaReceiptDialogVisible,
      isResendDialogVisible,
      resendChannel,
      onMouseDown,
      copyPreviewLink,
      downloadPolicy,
      downloadScreenshot,
      downloadVisaReceipt,
      deleteVoucher,
      isDeleteVoucherDialogVisible,
      deleteVoucherLoading,
      icons: {
        mdiPrinter,
        mdiFileDocumentOutline,
        mdiClose,
        mdiOpenInNew,
        mdiWhatsapp,
        mdiMessageProcessingOutline,
        mdiContentCopy,
        mdiPaperclip,
        mdiDownload,
        mdiCardMinus,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/style.scss';

@page {
  size: A4;
  margin: 0mm;
}

.app-preview {
  .printable--theme {
    p {
      font-size: 14px;
    }
  }
  .mobile-text {
    p {
      font-size: 9px;
    }
  }
}

.insurance-policy-viewer,
.payment-screenshot-viewer {
  height: 80vh;
  width: 100%;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .receipt-action,
  .voucher-preview {
    display: none !important;
  }

  .app-preview {
    .printable--theme {
      border-radius: 0px;

      p {
        font-size: 14px;
      }

      .receipt-no-margin {
        margin-left: -52px !important;
      }

      .scan-margin {
        margin-left: -20px !important;
      }
    }
  }

  .app-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-preview {
    .receipt-header,
    .payment-details,
    .receipt-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
